// src/components/CustomMessage.js
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Box, Typography, Tooltip, IconButton } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

const CustomMessage = ({ position, avatar, text, date, feedback,feedbackComment }) => {
  const renderFeedback = () => {
    if (!feedback) return null;

    const { positive } = feedback;
    const comment = feedbackComment

    const Icon = positive ? ThumbUpIcon : ThumbDownIcon;
    const iconColor = positive ? 'success' : 'error';
    const tooltipTitle = comment ? comment : '';

    const iconButton = (
      <IconButton aria-label={positive ? 'Thumbs Up' : 'Thumbs Down'} size="small">
        <Icon color={iconColor} />
      </IconButton>
    );

    return comment ? (
      <Tooltip title={tooltipTitle} arrow>
        {iconButton}
      </Tooltip>
    ) : (
      iconButton
    );
  };

  return (
    <Box
      display="flex"
      justifyContent={position === 'right' ? 'flex-end' : 'flex-start'}
      alignItems="flex-start"
      mb={1}
    >
      {position === 'left' && (
        <img
          src={avatar}
          alt="avatar"
          style={{ width: 40, height: 40, borderRadius: '50%', marginRight: 10 }}
        />
      )}
      <Box
        position="relative"
        p={2}
        bgcolor={position === 'right' ? '#092F36' : '#e5e5e5'}
        color={position === 'right' ? 'white' : 'black'}
        borderRadius={10}
        maxWidth="60%"
        fontSize="0.875rem" // Decrease font size
      >
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{text}</ReactMarkdown>
        <Typography variant="caption" display="block" align={position === 'right' ? 'right' : 'left'}>
          {date}
        </Typography>
        {feedback && (
          <Box
            display="flex"
            justifyContent={position === 'right' ? 'flex-end' : 'flex-start'}
            mt={1}
          >
            {renderFeedback()}
          </Box>
        )}
      </Box>
      {position === 'right' && (
        <img
          src={avatar}
          alt="avatar"
          style={{ width: 40, height: 40, borderRadius: '50%', marginLeft: 10 }}
        />
      )}
    </Box>
  );
};

export default CustomMessage;
