import React from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Button, Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import InfoIcon from '@mui/icons-material/HelpOutline';

const TrainChatbot = ({ onTrainingStart, onTrainingComplete, disabled }) => {
    const { id } = useParams();
    const handleTrainChatbot = async () => {
        onTrainingStart();
        const token = localStorage.getItem('access_token');
    
        if (!token) {
            toast.error('No access token found. Please login.');
            return;
        }
    
        try {
            const trainChatbotPromise = axios.post(
                `${process.env.REACT_APP_API_URL}/api/train`,
                { chatbot_id: id },
                {
                    headers: {
                        Authorization: `${token}`,
                    },
                }
            );
    
            toast.promise(
                trainChatbotPromise,
                {
                    pending: 'Update in progress. This may take a few minutes...',
                    success: "Knowledge Base update complete",
                    error: "Failed updating Knowledge Base"
                },
                { position: "top-center" }
            );
    
            const response = await trainChatbotPromise;
            const { status, success } = response.data;
    
            // Check if success is true
            if (!success) {
                throw new Error('Training failed');
            }
    
            onTrainingComplete(status || "Update Failed");
            console.log('function called', status);
    
        } catch (error) {
            onTrainingComplete("Update Failed");
            console.error('Error training chatbot:', error);
            toast.error(`Error: ${error.message || 'Training failed unexpectedly'}`);
        }
    };
    



    return (
        <div>
            <Tooltip title="To update the AI assistant with the latest knowledge base, please upload all content first and then click here." placement="top">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button variant="contained" onClick={handleTrainChatbot} disabled={disabled}  sx={{color: disabled ? "rgba(0, 0, 0, 0.46) !important" :"white",
}}>
                        Update AI assistant with Latest Content
                        <InfoIcon style={{ marginLeft: "0.5rem", color: disabled ? "rgba(0, 0, 0, 0.46)" : "white",}} />

                    </Button>
                   
                </div>
            </Tooltip>
        </div>
    );
};

export default TrainChatbot;
