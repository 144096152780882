import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Typography,
  Divider,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
  IconButton,
  Unstable_Grid2 as Grid
} from '@mui/material';
import Connect from '../components/Connect';
import ConnectInstagram from '../components/ConnectInstagram';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import io from 'socket.io-client';
import CloseIcon from '@mui/icons-material/Close';
import Messenger from '../images/messenger.webp';
import Instagram from '../images/instagram.webp';
import { QRCodeSVG } from 'qrcode.react';
import whatsapp from '../images/whatsapp.webp';

const ChannelsTab = ({ details = [] }) => {
  const [qrCode, setQrCode] = useState('');
  const socketRef = useRef(null); // Use useRef to persist socket across renders
  const { id } = useParams();
  const [connectionStatus, setConnectionStatus] = useState('disconnected'); // 'connected', 'disconnected', 'connecting'
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  // Cleanup socket on component unmount
  useEffect(() => {
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, []);

  const handleClose = () => {
    setOpenDialog(false);
    handleDisconnectWhatsApp();
  };

  const handleConnectWhatsApp = () => {
    setLoading(true);
    setOpenDialog(true);
    setConnectionStatus('connecting');

    // Initialize Socket.IO connection
    const newSocket = io(process.env.REACT_APP_NODEJS_API_URL, {
      transports: ['websocket'], // Force WebSocket to avoid polling
      reconnectionAttempts: 5, // Optional: Attempt to reconnect 5 times
      timeout: 10000, // Optional: 10 seconds timeout
    });

    socketRef.current = newSocket;
    // Log connection status
    newSocket.on('connect', () => {
      console.log('Socket connected:', newSocket.id);
      // Join the specific chatbot room
      newSocket.emit('join', id);
      console.log(`Emitting 'join' for chatbot_id: ${id}`);

      // Emit 'start-client' event to initiate WhatsApp client
      newSocket.emit('start-client', id);
      console.log(`Emitting 'start-client' for chatbot_id: ${id}`);
    });

    newSocket.on('connect_error', (error) => {
      console.error('Connection Error:', error);
      toast.error('Failed to connect to the server.');
      setLoading(false);
      setOpenDialog(false);
    });

    // Listen for QR code
    newSocket.on('qr', (qr) => {
      console.log('Received QR from server:', qr);
      setQrCode(qr);
      setConnectionStatus('connecting');
    });

    // Listen for ready event
    newSocket.on('ready', () => {
      toast.success('WhatsApp client is ready!');
      setQrCode('');  // Clear QR code once connected
      setConnectionStatus('connected');
      setLoading(false);
      setOpenDialog(false);
    });

    // Handle authentication failure
    newSocket.on('auth_failure', (msg) => {
      toast.error('Authentication failed: ' + msg);
      setLoading(false);
      setOpenDialog(false);
      setConnectionStatus('disconnected');
      newSocket.disconnect();
      socketRef.current = null;
    });

    // Handle client disconnection
    newSocket.on('disconnected', (reason) => {
      toast.info('WhatsApp client disconnected: ' + reason);
      setConnectionStatus('disconnected');
      setLoading(false);
      setOpenDialog(false);
      newSocket.disconnect();
      socketRef.current = null;
      setQrCode('');
    });

    // Handle generic error events
    newSocket.on('error', (error) => {
      console.error('Socket.IO error:', error);
      toast.error('An error occurred with the connection.');
      setConnectionStatus('disconnected');
      setLoading(false);
      setOpenDialog(false);
      newSocket.disconnect();
      socketRef.current = null;
    });

    // Handle socket disconnection from server
    newSocket.on('disconnect', (reason) => {
      console.log('Socket disconnected:', reason);
      if (connectionStatus === 'connected') {
        setConnectionStatus('disconnected');
        toast.info('Socket disconnected');
      }
    });
  };

  const handleDisconnectWhatsApp = () => {
    if (socketRef.current && socketRef.current.connected) {
      // If an active socket exists and is connected, emit 'disconnect-client'
      socketRef.current.emit('disconnect-client', id, (response) => {
        console.log(response);
        // toast.success('WhatsApp client disconnected successfully.');
      });
      socketRef.current.on('disconnected', (error) => {
        toast.success('WhatsApp disconnected');
        // Optionally, disconnect the main socket after emitting
        socketRef.current.disconnect();
        setQrCode('');
        setConnectionStatus('disconnected');
      });
    } else {
      // If no active socket, create a temporary socket to emit 'disconnect-client'
      const tempSocket = io(process.env.REACT_APP_NODEJS_API_URL, {
        transports: ['websocket'],
        reconnectionAttempts: 1,
        timeout: 5000,
      });

      tempSocket.on('connect', () => {
        console.log('Temporary socket connected for disconnecting.');
        tempSocket.emit('disconnect-client', id, (response) => {
          console.log(response);
          //toast.success('WhatsApp client disconnected successfully.');
        });
      });

      tempSocket.on('connect_error', (error) => {
        console.error('Temporary socket connection error:', error);
        toast.error('Failed to disconnect WhatsApp client.');
        tempSocket.disconnect();
      });
      tempSocket.on('disconnected', (error) => {
        console.error('Temporary socket connection error:', error);
        toast.success('WhatsApp disconnected');
        // Optionally, disconnect the main socket after emitting
        tempSocket.disconnect();
        setQrCode('');
        setConnectionStatus('disconnected');
      });
    }
  };

  // Function to copy the embed code
  const handleCopyCode = () => {
    const code = `<script>
window.embeddedChatbotConfig = {
  chatbotId: "${id}"}
</script>
<script
src="https://www.aissie.com.au/chat-widget-bundle.js"
chatbotId="${id}"
defer>
</script>
<div id="aissie-chat"></div>`;
    navigator.clipboard.writeText(code);
    toast.success('Copied successfully!', {
      position: 'top-center',
    });
  };

  return (
    <Box sx={{ padding: { xs: '1rem', sm: '2rem' } }}>
      <Typography variant="h4" fontWeight="700" padding="1rem 0">
        Add to Website/Social Media
      </Typography>
      <Paper square={false} elevation={4} sx={{ width: '100%', padding: '1rem', boxSizing: 'border-box' }}>
        <Box mt={2}>
          <Typography variant="h6" gutterBottom fontWeight="700">
            Add the AI assistant to your website
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Copy and paste the following script into your website to embed the AI assistant, right before
            the closing body tag.
          </Typography>
          <Box mb={2} p={2} bgcolor="#f2f2f2" borderRadius={4} sx={{ overflowX: 'auto', mt: 1 }}>
            <pre style={{ margin: 0, whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
              {`<script>
window.embeddedChatbotConfig = {
  chatbotId: "${id}"}
</script>
<script
src="https://www.aissie.com.au/chat-widget-bundle.js"
chatbotId="${id}"
defer>
</script>
<div id="aissie-chat"></div>`}
            </pre>
            <Box mt={1} display="flex" justifyContent="flex-end">
              <Button variant="contained" onClick={handleCopyCode}>
                Copy Code
              </Button>
            </Box>
          </Box>
        </Box>
        <Divider />

        {/* Facebook and Instagram Pages Section */}
        <Typography variant="h6" gutterBottom fontWeight="700" mt={2}>
          Connect the AI assistant to your Facebook and Instagram accounts
        </Typography>
        <Typography variant="body2" color="textSecondary">
          You can link the AI assistant to your Facebook or Instagram accounts. This allows your
          customers to interact first with the AI assistant, which is preconfigured to respond to
          enquiries related to your domain.
        </Typography>
        <Box mt={2}>
          <Grid container spacing={2}>
            {/* WhatsApp Connection 
            <Grid item xs={12} sm={6} md={4}>
              <Paper square={false} elevation={4} sx={{ padding: '1rem', height: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <img
                    src={whatsapp}
                    alt="Whatsapp"
                    className="hero-image-connect"
                    style={{ height: 'auto', marginBottom: '1rem' }}
                  />

                  {connectionStatus === 'connected' ? (
                    <Button variant="contained" color="warning" onClick={handleDisconnectWhatsApp} fullWidth>
                      Disconnect WhatsApp
                    </Button>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        onClick={handleConnectWhatsApp}
                        fullWidth
                        disabled={loading}
                        sx={{ mb: 1 }}
                      >
                        {connectionStatus === 'connecting' ? 'Connecting...' : 'Connect WhatsApp'}
                      </Button>

                      <Button
                        variant="contained"
                        color="warning"
                        onClick={handleDisconnectWhatsApp}
                        fullWidth
                      >
                        Disconnect WhatsApp
                      </Button>
                    </>
                  )}

                  <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth="xs">
                    <DialogTitle>
                      {qrCode ? 'Scan the QR Code' : 'Connecting to WhatsApp...'}
                      <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                          position: 'absolute',
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent>
                      {qrCode ? (
                        <Box display="flex" flexDirection="column" alignItems="center">
                          <QRCodeSVG value={qrCode} size={200} />
                          <Typography variant="body1" mt={2} textAlign="center">
                            Please scan this QR code using your WhatsApp app. After scanning, it may take a few
                            minutes to process. Kindly wait during this time.
                          </Typography>
                        </Box>
                      ) : (
                        <Box display="flex" alignItems="center" justifyContent="center" p={2}>
                          <CircularProgress />
                          <Typography variant="body1" marginLeft={2}>
                            Please wait while we generate the QR code...
                          </Typography>
                        </Box>
                      )}
                    </DialogContent>
                  </Dialog>
                </Box>
              </Paper>
            </Grid>
*/}
            {/* Messenger Connection */}
            <Grid item xs={12} sm={6} md={4}>
              <Paper square={false} elevation={4} sx={{ padding: '1rem', height: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <img
                    src={Messenger}
                    alt="Messenger"
                    className="hero-image-connect"
                    style={{  height: 'auto', marginBottom: '1rem' }}
                  />
                  <Connect details={details} />
                </Box>
              </Paper>
            </Grid>

            {/* Instagram Connection */}
            <Grid item xs={12} sm={6} md={4}>
              <Paper square={false} elevation={4} sx={{ padding: '1rem', height: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <img
                    src={Instagram}
                    alt="Instagram"
                    className="hero-image-connect"
                    style={{ height: 'auto', marginBottom: '1rem' }}
                  />
                  <ConnectInstagram details={details} />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        <Divider sx={{ mt: 2 }} />
      </Paper>
    </Box>
  );
};

export default ChannelsTab;
