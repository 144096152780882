import React, { useState } from 'react';
import { FileUploader } from "react-drag-drop-files";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const FileUpload = ({ type, multiple, details, onSuccessfulUpload, updateQasList, fileTypes, label }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const handleFileUpload = (files) => {
    setLoading(true);
    let fileArray = files;
    if (multiple) {
      fileArray = Array.from(files);
      setUploadedFiles([...uploadedFiles, ...files]);
    }
    handleUpload(fileArray);
  };

  const handleUpload = async (files) => {
    const formData = new FormData();
    if (type === 'quote') {
      // Convert files to an array if it's not already
      const filesArray = Array.isArray(files) ? files : [files];

      // Map over each file to create a new File object with the updated filename
      const updatedFiles = filesArray.map((file) => {
        // Define your logic for the new filename
        const newFilename = `quote_${file.name}`;

        // Create a new File object with the new filename
        return new File([file], newFilename, {
          type: file.type,
          lastModified: file.lastModified,
        });
      });

      // Append the updated files to FormData
      updatedFiles.forEach((file) => {
        formData.append(multiple ? 'files' : 'file', file);
      });
    } else {
      (Array.isArray(files) ? files : [files]).forEach((file) => {
        formData.append(multiple ? 'files' : 'file', file);
      });
    }
    formData.append('chatbot_id', id);
    formData.append('type', type);

    const token = localStorage.getItem('access_token');

    const uploadFiles = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: token,
          },
        });

        // Process successful response
        const uploadedFilesData = response.data.map(fileData => ({
          file_name: fileData.file_name,
          source_id: fileData.source_id,
          num_of_chars: fileData.num_of_chars,
          updated: fileData.updated
        }));

        // Update qasList if qas are returned in the response
        if (response.data[0]?.qas) {
          updateQasList(response.data[0].qas);
        } else {
          onSuccessfulUpload(uploadedFilesData);
          setUploadedFiles([]);
        }

        return response.data;
      } catch (error) {
        // Check if the error response exists and has a specific error message
        if (error.response && error.response.data && error.response.data.error) {
          throw new Error(error.response.data.error);
        } else {
          // If not, throw a generic error
          throw new Error('Failed to upload file(s).');
        }
      }
    };

    toast.promise(
      uploadFiles(),
      {
        pending: 'Uploading file(s)...',
        success: 'File(s) uploaded successfully!',
        error: {
          render({ data }) {
            // `data` is the error thrown from `uploadFiles`
            return data.message || 'Failed to upload file(s).';
          },
        },
      },
      {
        position: "top-center",
      }
    )
    .then(data => {
      console.log('Upload successful:', data);
      setLoading(false);
    })
    .catch(error => {
      console.error('Error uploading files:', error);
      setLoading(false);
    });
  };

  return (
    <div className="file-upload-container">
      <FileUploader
        classes="custom-fileUploader"
        multiple={multiple}
        handleChange={handleFileUpload}
        name="file"
        types={fileTypes}
        label={label}
        maxSize={20} // Ensure maxSize is a number, not a string
      />
    </div>
  );
};

export default FileUpload;
